$(document).ready(function() {

    //Menü-Icon animation auslösen
    $('.menu-icon').click(function () {
        $(this).toggleClass('open');
        $('.nav0-1').slideToggle('fast');
    });

    /*if(window.matchMedia('(min-width: 992px)').matches){

            //console.log('größer als 1000');

            // Prüfen ob Untermenüpunkte existieren
            var submenuitem = $('nav ul li');
            submenuitem.find('a.sub').after('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

            // Submenüebenen (de)aktivieren
            $('nav .nav0-1 > ul > li').hoverIntent({
                over: function () {
                    var sub = $(this).find('a').next('div');
                    if (sub) {
                        $(this).find('a').addClass('active');
                        $(sub).addClass('active');
                    }
                },
                out: function() {
                    var sub = $(this).find('a').next('div');
                    if (sub) {
                        $(this).find('a').removeClass('active');
                        $(sub).removeClass('active');
                    }
                },
                timeout: 400
            });
        }
        else if(window.matchMedia('(max-width: 767px)').matches) {

            //console.log('kleiner als 1000');

            // Prüfen ob Untermenüpunkte existieren
            var submenuitem = $('nav ul li');
            submenuitem.find('div').prev('a').after('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

            // Submenüebenen (de)aktivieren
            $('nav ul li').click(function () {
                submenuitem.find('div.active').removeClass('active');
                submenuitem.find('div.active').prev('a').find('.dropdown-icon').removeClass('rotate');
                var sub = $(this).next('div');
                if (sub) {
                    $(sub).toggleClass('active');
                }
                submenuitem.find('div.active').prev('a').find('.dropdown-icon').addClass('rotate');
            });

            // Bei Klick außerhalb wieder schließen
            $(".nav-top").bind("clickoutside", function () {
                if ($('.menu-icon').hasClass('open')) {
                    $(".nav0-1").slideUp('fast');
                    $('.menu-icon').removeClass('open');
                }
                $(".nav0-2").removeClass('active');
            });
        }*/

    if(window.matchMedia('(max-width: 767px)').matches) {

        // Prüfen ob Untermenüpunkte existieren
        var submenuitem = $('nav ul li');
        submenuitem.find('div').prev('a').after('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

        // Submenüebenen (de)aktivieren
        $('nav ul li span.dropdown-icon').click(function () {
            submenuitem.find('div.active').prev('span.dropdown-icon').removeClass('rotate');
            var sub = $(this).next('div');
            if (sub) {
                $(sub).toggleClass('active');
            }
            submenuitem.find('div.active').prev('span.dropdown-icon').addClass('rotate');
        });
    }

    else if(window.matchMedia('(min-width: 992px)').matches){

        // Prüfen ob Untermenüpunkte existieren
        var submenuitem = $('nav ul li');
        submenuitem.find('div').prev('a').append('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

        // Submenüebenen (de)aktivieren
        $('nav ul li').hoverIntent(function () {
            submenuitem.find('div.active').prev('a').find('.dropdown-icon').removeClass('rotate');
            var sub = $(this).find('.nav0-2');
            if (sub) {
                $(sub).toggleClass('active');
            }
            submenuitem.find('div.active').prev('a').find('.dropdown-icon').addClass('rotate');
        });
    }


    //$('.nav0-1 > ul > li > a').hoverIntent(function(){
    //   $(this).next('.nav0-2').toggleClass('active');
    //});

/*
    if (Modernizr.touch) {
        alert('Touch Screen');
    } else {
        alert('No Touch Screen');
    }
*/

    $('.slider').slick({
        dots: true,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.slider-logos').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 3,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    });

    $(function(){
        /* overlay richtig positionieren */
        var $overlay = $('.referenzen__item__details');
        var leftPos = $(window).width() / 2 - $overlay.outerWidth() / 2 - 20;
        var topPos = ($(window).height() / 2) - ($overlay.outerHeight() / 2);
        $overlay.css({
            left: leftPos,
            top: topPos
        });
        /*$('.close-overlay').click(function() {
         $('.info-overlay').fadeOut();
         });*/

    });


    $(".fancybox").fancybox();

});

var shrinkHeader = 110;
$(window).scroll(function() {
    var scroll = getCurrentScroll();
    if ( scroll >= shrinkHeader ) {
        $('header').addClass('fixed');
    }
    else {
        $('header').removeClass('fixed');
    }
});
function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}